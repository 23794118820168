import { appConfig } from "./config/app.config";

declare global {
	interface Window {
		ym: any,
    dentolo_quiz_ec: any[]
	}
}

const SRC = "https://mc.yandex.ru/metrika/tag.js";

const script = document.createElement('script');
const firstScript = document.getElementsByTagName('script')[0]; 

const ym:any = window.ym || function() {
	ym.a = ym.a || [];
	ym.a.push(arguments);
};

ym.l = new Date().getTime();

window.ym = ym;
window.dentolo_quiz_ec = window.dentolo_quiz_ec || [];

script.async = true;
script.src = SRC;
firstScript?.parentNode?.insertBefore(script, firstScript);

window.ym(appConfig().ya_metrika_counter, "init", {
	clickmap:true,
	trackLinks:true,
	accurateTrackBounce:true,
  ecommerce:"dentolo_quiz_ec"
});


export default (...args:any) => window.ym(appConfig().ya_metrika_counter, ...args);