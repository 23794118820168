import { appConfig } from "./config/app.config";
import modificators from "./modificators.json";
import yaMetrika from "./ya-metrika";

interface IGO_Test {
  test_id: string;
  name: string;
  code: string;
}

interface ITestSetItem {
  name: string;
  order: number;
  variant: any;
}

const successCombination = [0,0,1,1,1,2,0,0,0,1,1,0,2,1,1,1,0,0,0];
const defaultCombination = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

const blackList: string[] = [
  // "vse-svoi.ru",
  // "implant-bestprice.ru",
  // "mydentist.ru",
  // "dantisti.ru"
];

export async function getGOTests(clientId: string, comb?: number) {
  try {
    if (comb != undefined) {
      if (comb == 1) return modificators.map(mod => {
        return {
          name: mod.name,
          order: mod.order,
          variant: mod.variants[successCombination[mod.order]]
        }
      });
      return;
    }

    const response = await fetch(`${appConfig().api_url}/go-test/${clientId}?general=1`);
    const tests: IGO_Test[] = await response.json();
    console.log(tests);
    let testsSet: ITestSetItem[] | undefined = undefined;
    let testingCombination = successCombination;
    
    const inBlackList = blackList.some(url => {
      return window.location.href.includes(url);
    });

    if (inBlackList) {
      testingCombination = defaultCombination;
    }

    if (tests && tests.length) {
      for (let test of tests) {
        const variant = Number(window.google_optimize?.get(test.test_id));
  
        
        if (variant === variant) {
          yaMetrika('params', { go_test_variant: variant});
          yaMetrika('params', { go_test_code: test.code });
          
          if (variant === 0) testingCombination = defaultCombination;
          if (variant === 1) testingCombination = successCombination;

          console.log("variant: ", variant);
          console.log("test_id: ", test.test_id);
          console.log("testing_combination: ", testingCombination);

          break;
        }
      }
    }
    
    testsSet = modificators.map(mod => {
      return {
        name: mod.name,
        order: mod.order,
        variant: mod.variants[testingCombination[mod.order]]
      }
    });
    
    console.log(testsSet);
  
    return testsSet;

  } catch (e) {
    console.log(e);

    return undefined;
  }
}