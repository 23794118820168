export const appConfig = () => ({
	env: process.env.NODE_ENV,
	quiz_url: String(process.env.QUIZ_URL),
	check_url: String(process.env.CHECK_URL),
	ya_metrika_counter: Number(process.env.YA_METRIKA_NUMBER),
  gtag_id: String(process.env.GTAG_ID),
  go_id: String(process.env.GO_ID),
	quiz_develop_url: String(process.env.QUIZ_DEVELOP_URL),
  global_object: JSON.parse(String(process.env.GLOBAL_OBJECT)),
  api_url: String(process.env.API_URL)
});

export type AppConfig = ReturnType<typeof appConfig>;
