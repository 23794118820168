import { appConfig } from './config/app.config';
import { QUIZ_DISABLED_WARN } from './constants';

export function getQueryParams(params:string[]):{[key:string]:string} {
	const result:{[key:string]:string} = {};

	params.forEach(param => {
		const value = getQueryParam(param);
		if (value) result[param] = value;
	});

	return result;
}

export function getQueryParam(name:string):string {
	var name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
	var results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export async function checkForEnable(id:string):Promise<boolean> {
	try {
		const response = await fetch(`${appConfig().check_url}/${id}`);

		if (response.status != 200) {
			console.warn(QUIZ_DISABLED_WARN);
			return Promise.resolve(false);
		}

	} catch(e) { return Promise.resolve(true) }

	return Promise.resolve(true);
}

export function getCookie(name: string) {
  var matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}