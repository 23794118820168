import { Subscription } from './observer.model';

interface Subscriber {
	eventName: any,
	notify(event:any):void
}

export default class EventEmitter<EventName=string, Event=any> {
	constructor() {}

	private subscribers:Subscriber[] = [];

	public subscribe(eventName:EventName, notify:(event:Event)=>void):Subscription {
		this.subscribers.push({ eventName, notify });

		return { 
			unsubscribe: ():void => this.unsubscribe(eventName, notify),
			subscriber: () => ({ eventName, notify })
		};
	}

	public unsubscribe(eventName:EventName, notify:(event:Event)=>void):void {
		this.subscribers.filter(sub => (sub.eventName != eventName || sub.notify != notify));
	}

	public emit(eventName:EventName, event:Event):void {
		this.subscribers.filter(subscriber => subscriber.eventName == eventName)
			.forEach(subscriber => {
        try {
          subscriber.notify(event)
        } catch(e) {
          console.log("emit event error", e);
        }
      });
	}
}