export const OPEN_QUIZ:string = 'OPEN_QUIZ';
export const CLOSE_QUIZ:string = 'CLOSE_QUIZ';
export const INIT:string = 'INIT';
export const REPLACE_PHONE:string = 'REPLACE_PHONE';
export const REPLACE_DESCRIPTOR:string = 'REPLACE_DESCRIPTOR';
export const WIDGET_ERROR:string = 'Widget is not initialized';
export const INIT_ERROR:string = 'DentoloQuiz is not initialized';
export const WIDGET_EXISTENCE_ERROR:string = 'Widget has been destroyed';
export const STANDART_UTM_MARKS:string[] = ['utm_source', 'utm_content', 'utm_term', 'utm_medium', 'utm_campaign', 'yclid', 'gclid'];

export const LOCAL_STORAGE_AUTOPOPUP = 'dentolo_autopopup';
export const QUIZ_DISABLED_WARN = 'dentolo quiz is disabled for this client';
export const CUSTOM_LEAD_PARAMS = 'CUSTOM_LEAD_PARAMS';
export const TEST_VARIATIONS = "TEST_VARIATIONS";
export const CUSTOM_CONTENT = "CUSTOM_CONTENT";

export enum DentoloEventName {
	INIT='init', 
	OPEN='open', 
	CLOSE='close',
	CLOSE_WITH_ERROR='get_error', 
	LOAD_QUIZ='load_quiz', 
	LOAD_CONTENT='load_content', 
	START_QUIZ='start_quiz', 
	GET_LEAD='get_lead', 
	CHOOSE_SERVICE='choose_service',
  SHOW_SERVICES='show_services',
	GET_APPOINTMENT='get_appointment', 
	REACH_PHONE_FORM='reach_phone_form', 
	START_TYPING_PHONE='start_typing_phone',
	CLICK_HEADER_PHONE='click_header_phone',
	RICH_QUESTION='reach_question',
	CLICK_TRADEMARK='click_trademark', 
	SHOW_FLYING_BTN='show_flying_btn', 
	SHOW_PHONE_FORM_VISUAL='show_phone_form_visual',
	START_BY_FLYING_BTN='start_by_flying_btn', 
	START_BY_SCROLL='start_by_scroll', 
	CLICK_ADDITIONAL_BUTTON='click_additional_button', 
  CLICK_SIGN_UP='click_sign_up',
	GET_CLIENT_INFO='get_client_info', 
	DEFINE_AB_TEST_CASE='define_ab_test_case', 
	DEFINE_SERVICE='define_service',
	CLICK_WHATSAPP_BUTTON='click_whatsapp_button',
	EVENT_GO_TO_SERVICE_SELECTION = 'go_to_service_selection',
	GET_ECOMMERCE_ACTION = 'get_ecommerce_action',
  CLICK_AGREEMENT = 'click_agreement',
  START_BY_MAIN_BTN = 'start_by_main_btn',
  SEND_PARAMS="send_params",
	EVENT_GET_FORM_TYPE="get_form_type"
}

export enum Service {
	IMPLANTATION='implantation',
	PROSTHETICS='prosthetics',
	BRACES='braces',
	VENEERS='veneers',
	WHITENING='whitening',
	TREATMENT='treatment',
	REMOVE='remove',
	PEDIATRICS='pediatrics',
	GUMS='gums',
	CROWNS='crowns',
	CLEANING='cleaning',
	REMOVE_WISDOM='rem_wisdom',
  ONLY_BRACES='only_braces',
  ONLY_ELINERS='only_eliners',
  BITE='bite'
}